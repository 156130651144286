export interface DescriptionField {
  name: string;
  type: string;
  label: string;
  language: string;
}

export interface NameField {
  name: string;
  type: string;
  label: string;
  language: string;
}

export interface ImageField {
  name: string;
  label: string;
  language: string;
}

export interface VideoField {
  name: string;
  label: string;
  language: string;
}

export interface FieldForValidate {
  name: string;
  path: string;
}

export const descriptionFields: DescriptionField[] = [
  {
    name: 'description_ru',
    type: 'Textarea',
    label: 'on_ru',
    language: 'ru',
  },
  {
    name: 'description_en',
    type: 'Textarea',
    label: 'on_en',
    language: 'en',
  },
  {
    name: 'description_ky',
    type: 'Textarea',
    label: 'on_ky',
    language: 'ky',
  },
];

export const nameFields: NameField[] = [
  {
    name: 'name_ru',
    type: 'text',
    label: 'on_ru',
    language: 'ru',
  },
  {
    name: 'name_en',
    type: 'text',
    label: 'on_en',
    language: 'en',
  },
  {
    name: 'name_ky',
    type: 'text',
    label: 'on_ky',
    language: 'ky',
  },
];

export const imageFields: ImageField[] = [
  {
    name: 'image_ru',
    label: 'on_ru',
    language: 'ru',
  },
  {
    name: 'image_ky',
    label: 'on_ky',
    language: 'ky',
  },
  {
    name: 'image_en',
    label: 'on_en',
    language: 'en',
  },
];

export const videoFields: VideoField[] = [
  {
    name: 'video_ru',
    label: 'on_ru',
    language: 'ru',
  },
  {
    name: 'video_en',
    label: 'on_en',
    language: 'en',
  },
  {
    name: 'video_ky',
    label: 'on_ky',
    language: 'ky',
  },
];

export const fieldsForValidate: FieldForValidate[] = [
  { name: 'name_ru', path: 'name.ru' },
  { name: 'name_en', path: 'name.en' },
  { name: 'name_ky', path: 'name.ky' },
  { name: 'description_en', path: 'description.en' },
  { name: 'description_ru', path: 'description.ru' },
  { name: 'description_ky', path: 'description.ky' },
  { name: 'image_en', path: 'image.en' },
  { name: 'image_ru', path: 'image.ru' },
  { name: 'image_ky', path: 'image.ky' },
  { name: 'video_en', path: 'video.en.url' },
  { name: 'video_ru', path: 'video.ru.url' },
  { name: 'video_ky', path: 'video.ky.url' },
  { name: 'ageRestrictions', path: 'ageRestrictions' },
  { name: 'coursePrice', path: 'coursePrice.value' },
];
