// External dependencies
import { Div, Image, Text } from 'atomize';
import React from 'react';
import { Trans } from 'react-i18next';

interface CourseCardProps {
  image: string;
  title: string;
  description: string;
  watchTime: number;
}

export default function CourseCard({
  image,
  title,
  description,
  watchTime,
}: CourseCardProps) {
  return (
    <Div
      d="flex"
      flexDir={{ xs: 'column', md: 'row' }}
      rounded="20px"
      align="center"
      bg="gray"
      style={{ overflow: 'hidden', gap: '5px' }}
    >
      <Div maxW={{ xs: '100%', md: '300px', lg: '200px' }}>
        <Image
          maxH="100px"
          h="100%"
          src={
            image ||
            'https://www.nbmchealth.com/wp-content/uploads/2018/04/default-placeholder.png'
          }
        />
      </Div>
      <Div p={{ y: '15px', x: '30px' }}>
        <Text
          textWeight="600"
          textSize={{ xs: 'display1', md: 'display2', lg: 'display' }}
        >
          {title}
        </Text>
        {watchTime ? (
          <Text textSize={{ xs: 'body', lg: 'smallSize2' }}>
            {watchTime} <Trans>minute</Trans>
          </Text>
        ) : null}

        <Text textSize={{ xs: 'body', lg: 'smallSize2' }}>{description}</Text>
      </Div>
    </Div>
  );
}
