// External dependencies
import { Div, Text } from 'atomize';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Local dependencies
import { FileEventTypes } from '@components/courses/createCourse/VideoUploader';
import {
  LocalizedVideoMap,
  VideoImageUploaderType,
} from '@components/courses/types';
import VideoImageUploader from '../VideoImageUploader';

interface RenderVideoListProps {
  course: any;
  parents: LocalizedVideoMap;
  uploadVideoToS3: (name: string, e: FileEventTypes) => void | any;
  videoErrors: any;
  videoList: any[];
}

const videoKeys = ['videoOne', 'videoTwo', 'videoThree'];

export default function RenderVideoList({
  course,
  parents,
  uploadVideoToS3,
  videoErrors,
  videoList,
}: RenderVideoListProps) {
  const { t } = useTranslation();

  return (
    <>
      {videoList.map((element, index) => (
        <Div maxW="400px" w="100%" key={videoKeys[index]}>
          <Text textWeight="600">{t(element?.label)}</Text>
          <Div
            p="10px"
            bg={videoErrors?.[element.language] ? 'brand100' : 'gray'}
            rounded="md"
            maxW="400px"
            w="100%"
          >
            <iframe
              width="100%"
              height="200px"
              src={course?.video[element.language]?.url}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>

            <VideoImageUploader
              name={element.name}
              onChange={uploadVideoToS3}
              error={videoErrors?.[element.language]}
              type={VideoImageUploaderType.VIDEO}
            />
          </Div>
        </Div>
      ))}
    </>
  );
}
