// External dependencies
import { Div, Icon, Row, Text } from 'atomize';
import { Link, navigate } from 'gatsby';
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import Button from '@shared/ui/button';
import FormField from '@shared/ui/form/FormField';
import ErrorPopup from '@shared/ui/popup/ErrorPopup';
import Popup from '@shared/ui/popup/Popup';
import SuccessPopup from '@shared/ui/popup/SuccessPopup';
import { getItemFromLocalStorage } from '@shared/utils/helpers';
import { FileEventTypes } from '../createCourse/VideoUploader';
import { fieldsForValidate } from '../createCourse/models';
import CourseCard from './CourseCard';
import { fields, fieldsNames, images, videos } from './models';
import {
  getCourse,
  getCourseReset,
  updateCourseErrorReset,
  updateCourseRequest,
  updateCourseUpdateField,
} from './redux/action';
import { GetCourseState } from './redux/reducer';
import { RenderFormFields } from './ui/RenderFormFields';
import RenderImageList from './ui/RenderImageList';
import RenderVideoList from './ui/RenderVideoList';

export default function CourseDetails() {
  const { language } = useI18next();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    course,
    loading,
    error,
    isSuccess,
    nameRuError,
    nameEnError,
    nameKyError,
    descriptionEnError,
    descriptionRuError,
    descriptionKyError,
    imageEnError,
    imageRuError,
    imageKyError,
    videoEnError,
    videoRuError,
    videoKyError,
    ageRestrictionsError,
    coursePriceError,
  }: GetCourseState = useSelector((state: RootState) => state.course);

  const namesErrors = {
    ru: nameRuError,
    ky: nameKyError,
    en: nameEnError,
  };
  const descriptionErrors = {
    ru: descriptionRuError,
    ky: descriptionKyError,
    en: descriptionEnError,
  };
  const imageErrors = {
    ru: imageRuError,
    ky: imageKyError,
    en: imageEnError,
  };
  const videoErrors = {
    ru: videoRuError,
    ky: videoKyError,
    en: videoEnError,
  };

  useEffect(() => {
    const courseId = getItemFromLocalStorage('courseId');
    dispatch(getCourse(courseId));

    return () => {
      dispatch(getCourseReset());
    };
  }, []);

  function onUpdateFields(e) {
    const { name, value } = e.target;

    dispatch(updateCourseUpdateField({ [name]: value }));
  }

  async function uploadVideoToS3(name: string, event: FileEventTypes) {
    const url = 'https://';

    // For now, I left it like that, then you need to upload it to S3
    dispatch(updateCourseUpdateField({ [name]: url }));
  }

  function handleSave() {
    const emtyFields = [];
    fieldsForValidate.forEach((field) => {
      const value = getNestedValue(course, field.path);

      if (!value) {
        emtyFields.push(field.name);
        dispatch(updateCourseUpdateField({ [field.name]: value }));
      }
    });

    if (!emtyFields.length) {
      dispatch(updateCourseRequest(course));
    }
  }

  function getNestedValue(obj, path) {
    return path.split('.').reduce((value, key) => value[key], obj);
  }

  function handleError() {
    dispatch(updateCourseErrorReset());
  }

  function onSuccess() {
    dispatch(getCourseReset());
    navigate('/admin/courses/');
  }

  function navigateToAddCourseItem() {
    navigate('/admin/courses/create-course-item/');
  }

  return (
    <Div className="container" m={{ x: 'auto', b: '50px' }} p="30px 0">
      <Div m={{ b: '50px' }} p="50px 0">
        <Text textWeight="600" textSize="subtitle" textAlign="center">
          {course?.title?.[language]}
        </Text>
      </Div>

      {loading && (
        <Popup>
          <Div d="flex" justify="center" align="center" p="10px 50px 40px">
            <Icon name="Loading" size="40px" />
          </Div>
        </Popup>
      )}

      <Link to="/admin/courses" style={{ display: 'inline-block' }}>
        <Div
          d="flex"
          align="center"
          cursor="pointer"
          p="20px 0"
          m={{ l: '-13px' }}
        >
          <Icon name="LeftArrow" size="50px" />
          <Text textSize="subtitle" textWeight="600">
            {t('back')}
          </Text>
        </Div>
      </Link>

      <Div>
        <Text textWeight="600" textSize="heading" p={{ t: '30px' }}>
          <Trans>courseTitle</Trans>
        </Text>
        <Div
          p={{ y: '20px' }}
          d="flex"
          w="100%"
          justify="center"
          flexDir={{ xs: 'column', lg: 'row' }}
          style={{ gap: '40px' }}
        >
          <RenderFormFields
            fieldList={fieldsNames}
            parrentValue={course?.title}
            onChange={onUpdateFields}
            errors={namesErrors}
          />
        </Div>
      </Div>

      <Div>
        <Text textWeight="600" textSize="heading" p={{ t: '10px' }}>
          <Trans>description</Trans>
        </Text>
        <Div
          p={{ y: '20px' }}
          d="flex"
          style={{ gap: '40px' }}
          flexDir={{ xs: 'column', lg: 'row' }}
        >
          <RenderFormFields
            fieldList={fields}
            parrentValue={course?.description}
            onChange={onUpdateFields}
            errors={descriptionErrors}
          />
        </Div>
      </Div>

      <Div
        d="flex"
        flexDir={{ xs: 'column', lg: 'row' }}
        style={{ gap: '40px' }}
      >
        <Row style={{ whiteSpace: 'nowrap' }} w={{ xs: '100%', lg: '34%' }}>
          <FormField
            bg="basic_border_color"
            inputType="text"
            label={t('ageRestrictions')}
            inputValue={course?.ageRestrictions}
            inputName="ageRestrictions"
            onChange={onUpdateFields}
            error={ageRestrictionsError}
          />
        </Row>
        <Row p={{ r: '30px' }}>
          <FormField
            bg="basic_border_color"
            inputType="text"
            label={t('coursePrice')}
            inputValue={course?.coursePrice?.value}
            inputName="coursePrice"
            onChange={onUpdateFields}
            error={coursePriceError}
          />
        </Row>
      </Div>
      <Div
        w="100%"
        d="flex"
        flexDir="column"
        style={{ gap: '30px' }}
        p={{ t: '30px' }}
      >
        <Div>
          <Text textWeight="600" textSize="heading" p={{ b: '20px' }}>
            {t('Video')}
          </Text>
          <Div
            d="flex"
            justify="space-between"
            flexDir={{ xs: 'column', lg: 'row' }}
            style={{ gap: '20px' }}
          >
            <RenderVideoList
              course={course}
              videoList={videos}
              uploadVideoToS3={uploadVideoToS3}
              parents={course?.video}
              videoErrors={videoErrors}
            />
          </Div>
        </Div>

        <Div p={{ t: '30px' }}>
          <Text textWeight="600" textSize="heading" p={{ b: '20px' }}>
            {t('image')}
          </Text>
          <Div
            d="flex"
            justify="space-between"
            flexDir={{ xs: 'column', lg: 'row' }}
            style={{ gap: '20px' }}
          >
            <RenderImageList
              course={course}
              imageList={images}
              parents={course?.image}
              uploadVideoToS3={uploadVideoToS3}
              imageErrors={imageErrors}
            />
          </Div>
        </Div>

        <Div p={{ t: '30px' }}>
          <Text textWeight="600" textSize="heading" p={{ b: '30px' }}>
            <Trans>lessions</Trans> ({course?.totalDuration}
            <Trans>minute</Trans>)
          </Text>
          <Div d="flex" flexDir="column" style={{ gap: '20px' }}>
            {course?.courseItems?.map((courseItem, index) => (
              <CourseCard
                key={index}
                title={courseItem.name[language]}
                image={courseItem?.previewImage?.[language]}
                description={courseItem.description?.[language]}
                watchTime={courseItem?.currentWatchTime}
              />
            ))}
            <Div d="flex" justify="end">
              <Div maxW="300px">
                <Button typeColor="primary" onClick={navigateToAddCourseItem}>
                  <Trans>addCourseItem</Trans> +
                </Button>
              </Div>
            </Div>
          </Div>
        </Div>
        <Div d="flex" style={{ gap: '10px' }} p="20px 0">
          <Button
            typeColor="primary"
            style={{ textTransform: 'uppercase' }}
            onClick={handleSave}
            loading={loading}
          >
            <Trans>save</Trans>
          </Button>
          <Button
            typeColor="secondary"
            onClick={() => navigate('/admin/courses')}
          >
            <Trans>cancel</Trans>
          </Button>
        </Div>

        {error && (
          <ErrorPopup
            title="error"
            maxW="600px"
            submitText="close"
            onSubmit={handleError}
          >
            <Div>{error?.message}</Div>
          </ErrorPopup>
        )}

        {isSuccess && (
          <SuccessPopup
            title="ready"
            submitText="complete"
            onSubmit={onSuccess}
          >
            <Text>
              <Trans>courseUpdatedSuccessfully</Trans>
            </Text>
          </SuccessPopup>
        )}
      </Div>
    </Div>
  );
}
