// External dependencies
import { Div, Image, Text } from 'atomize';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import { FileEventTypes } from '@components/courses/createCourse/VideoUploader';
import {
  LocalizedUrlMap,
  VideoImageUploaderType,
} from '@components/courses/types';
import VideoImageUploader from '../VideoImageUploader';

interface RenderImageListProps {
  course: any;
  parents: LocalizedUrlMap;
  uploadVideoToS3: (name: string, e: FileEventTypes) => void | any;
  imageErrors: any;
  imageList: any[];
}

const imageKeys = ['imageOne', 'imageTwo', 'imageThree'];

export default function RenderImageList({
  course,
  parents,
  uploadVideoToS3,
  imageErrors,
  imageList,
}: RenderImageListProps) {
  const { t } = useTranslation();

  return (
    <>
      {imageList.map((element, index) => (
        <Div maxW="400px" w="100%" key={imageKeys[index]}>
          <Text textWeight="600">{t(element.label)}</Text>
          <Div
            p="10px"
            bg={imageErrors?.[element.language] ? 'brand100' : 'gray'}
            rounded="md"
            maxW="400px"
            w="100%"
            minH="400px"
            d="flex"
            flexDir="column"
            justify="space-between"
            style={{ gap: '10px' }}
          >
            <Image
              w="100%"
              style={{ objectFit: 'contain' }}
              src={course?.image[element.language]}
              alt={course?.title[element.language]}
            />
            <VideoImageUploader
              name={element.name}
              onChange={uploadVideoToS3}
              error={imageErrors?.[element.language]}
              type={VideoImageUploaderType.IMAGE}
            />
          </Div>
        </Div>
      ))}
    </>
  );
}
