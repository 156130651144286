// External  dependencies
import { Row } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import { NameField } from '@components/courses/createCourse/models';
import { LocalizedStringMapInput } from '@components/courses/types';
import FormField from '@shared/ui/form/FormField';

interface RenderFormFields {
  errors: any;
  fieldList: NameField[];
  parrentValue: LocalizedStringMapInput;
  onChange: Function;
}

export function RenderFormFields({
  fieldList,
  parrentValue,
  errors,
  onChange,
}: RenderFormFields) {
  const { t } = useI18next();

  return (
    <>
      {fieldList?.map((field) => (
        <Row key={field.name} w="100%">
          <FormField
            bg="basic_border_color"
            inputName={field.name}
            inputType={field.type}
            minH="100px"
            label={t(field.label)}
            inputValue={parrentValue?.[field?.language]}
            onChange={onChange}
            error={errors?.[field.language]}
          />
        </Row>
      ))}
    </>
  );
}
