export const fields = [
  { name: 'description_ru', type: 'Textarea', label: 'on_ru', language: 'ru' },
  { name: 'description_en', type: 'Textarea', label: 'on_en', language: 'en' },
  { name: 'description_ky', type: 'Textarea', label: 'on_ky', language: 'ky' },
];
export const fieldsNames = [
  { name: 'name_ru', type: 'text', label: 'on_ru', language: 'ru' },
  { name: 'name_en', type: 'text', label: 'on_en', language: 'en' },
  { name: 'name_ky', type: 'text', label: 'on_ky', language: 'ky' },
];

export const images = [
  { label: 'on_ru', name: 'image_ru', language: 'ru' },
  { label: 'on_en', name: 'image_en', language: 'en' },
  { label: 'on_ky', name: 'image_ky', language: 'ky' },
];
export const videos = [
  { label: 'on_ru', name: 'video_ru', language: 'ru' },
  { label: 'on_en', name: 'video_en', language: 'en' },
  { label: 'on_ky', name: 'video_ky', language: 'ky' },
];
