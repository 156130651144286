const errorMessageStyle = {
  fontSize: 'body',
  m: '.2rem 0 0 0',
  maxW: '270px',
  textColor: 'red',
};

const formStyle = {
  width: '-webkit-fill-available',
};

const defaultInputStyle = {
  borderColor: 'input_border_color',
  className: 'input',
  focusBorderColor: 'btn_primary_bg',
  h: '40px',
  p: { l: '1rem', r: '1.3rem' },
  rounded: '4px',
  textColor: 'table_cell_text_color',
  textSize: 'body',
  textWeight: '600',
};

const dropdownStyle = {
  borderColor: 'input_border_color',
  bg: 'input_bg',
  h: '40px',
  p: { l: '1rem', r: '1.3rem' },
  rounded: '4px',
  textColor: 'table_cell_text_color',
  textSize: 'body',
  textWeight: '600',
};

const defaultDropdownStyle = {
  borderColor: 'input_border_color',
  w: 'auto',
  rounded: '4px',
  textWeight: '700',
  textTransform: 'uppercase',
};

const inputCurrentWeightStyle = {
  borderColor: 'input_border_color',
  className: 'input',
  focusBorderColor: 'btn_primary_bg',
  h: '68px',
  p: { l: '1rem', r: '2.7rem' },
  rounded: '4px',
  textAlign: 'center',
  textColor: 'table_cell_text_color',
  textSize: 'display1',
  textWeight: '700',
  w: '100rem',
};

const labelStyle = {
  align: 'baseline',
  justify: 'left',
  maxW: '270px',
  m: '0 auto 0 0',
  textColor: 'label_text_color',
  textSize: 'body',
  textTransform: 'uppercase',
  textWeight: '700',
};

const labelSecondaryStyle = {
  ...labelStyle,
  p: '0 0 12px  0 ',
  textTransform: undefined,
  textWeight: '400',
};

const rowStyle = {
  align: 'center',
  d: 'flex',
  m: '0 -30px 1rem -30px',
  style: { whiteSpace: 'nowrap' },
};

const subMenuStyle = {
  ...labelStyle,
};

const wrapStyle = {
  bg: 'white',
  rounded: '4px',
  m: '0 auto',
  maxW: '330px',
  w: '330px',
  p: '0',
};

export {
  defaultInputStyle,
  defaultDropdownStyle,
  dropdownStyle,
  errorMessageStyle,
  formStyle,
  inputCurrentWeightStyle,
  labelStyle,
  labelSecondaryStyle,
  rowStyle,
  subMenuStyle,
  wrapStyle,
};
