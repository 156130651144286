// Externall dependencies
import { Div, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useRef } from 'react';

// Local dependencies
import { errorMessageStyle } from '@shared/ui/form/FormStyles';
import { VideoImageUploaderType } from '../types';

type FileEventTypes = React.ChangeEvent<HTMLInputElement>;

interface VideoImageUploaderProps {
  onChange: Function;
  name: string;
  error?: any;
  type: string;
}

export default function VideoImageUploader({
  error,
  name,
  onChange,
  type,
}: VideoImageUploaderProps) {
  const inputFileRef = useRef();
  const isVideo = type === VideoImageUploaderType.VIDEO;

  function handleClick() {
    inputFileRef?.current?.click();
  }

  function handlePhotoChange(event: FileEventTypes) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = e.target?.result as string;
      };

      reader.readAsDataURL(event.target.files[0]);

      onChange(name, event);
    }
  }

  function handleVideoChange(event: FileEventTypes) {
    const file = event.target.files[0];

    if (file) {
      const url = URL.createObjectURL(file) as string;
    }

    onChange(name, event);
  }

  return (
    <Div>
      <input
        type="file"
        className="image-upload"
        style={{ opacity: '0' }}
        onChange={isVideo ? handleVideoChange : handlePhotoChange}
        ref={inputFileRef}
        accept={isVideo ? 'video/mp4,video/x-m4v,video/*' : 'image/*'}
        name={name}
        hidden={true}
      />
      <Text
        bg="gray_bg_hover"
        d="inline"
        p="5px 20px"
        rounded="sm"
        cursor="pointer"
        onClick={handleClick}
      >
        <Trans>change</Trans>
      </Text>
      <Text textSize="caption" {...errorMessageStyle} h="16px">
        <Trans>{error && error.code}</Trans>
      </Text>
    </Div>
  );
}
